<template>
  <v-item-group v-model="vendor.payments" multiple>
    <v-row class="px-3">
      <v-col v-for="(payment, idx) in payments" :key="idx" cols="12" md="2">
        <v-item v-slot="{ active, toggle }" :value="payment.id">
          <v-card
            :color="
              active ? 'primary white--text' : 'grey lighten-4 secondary--text'
            "
            class="d-flex flex-column align-center justify-center"
            height="100"
            :dark="active ? true : false"
            @click="toggle"
          >
            <v-icon>{{ payment.icon }}</v-icon>
            <h4>{{ payment.type }}</h4>
          </v-card>
        </v-item>
      </v-col>
    </v-row>
    
    <!------------------------------------------
      Se o tipo de pagamento for TRANSFERÊNCIA
    ------------------------------------------->
    <TransferTypePayment
      v-if="vendor.payments.find(el => el == 5)"
      :transfers="vendor.transfers"
      @addTransfer="addTransfer"
      @removeTransfer="removeTransfer"
    />


  </v-item-group>
</template>

<script>
export default {
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  components: {
    TransferTypePayment: () => import('./TransferTypePayment')
  },
  data: () => ({
    payments: [
      {
        id: 1,
        type: "Boleto",
        icon: "fas fa-barcode",
      },
      {
        id: 2,
        type: "Cartão",
        icon: "fas fa-credit-card",
      },
      {
        id: 3,
        type: "Cheque",
        icon: "fas fa-money-check",
      },
      {
        id: 4,
        type: "Dinheiro",
        icon: "fas fa-coins",
      },
      {
        id: 5,
        type: "Transferência",
        icon: "fas fa-exchange-alt",
      },
    ],
  }),
  methods: {
    addTransfer() {
      this.vendor.transfers.push({})
    },
    removeTransfer(transfer) {
      let idx = this.vendor.transfers.indexOf(transfer)
      this.vendor.transfers.splice(idx, 1)
    }
  }
};
</script>